<template>
  <div class="base-datepicker-calendar" :class="classes">
    <div class="base-datepicker-calendar__header">
      <plain-button
        icon="arrow-left"
        size="small"
        class="base-datepicker-calendar__prev"
        @click="onPrevClick"
      />
      <div class="base-datepicker-calendar__header-center standart-text">
        <div
          v-if="currentMode === 'days' || currentMode === 'months'"
          key="month"
          class="base-datepicker-calendar__header-month"
          @click="toggleMode('months')"
        >
          {{ getFormattedMonthByNumber(current.month) }}
        </div>
        <div
          v-if="currentMode === 'days' || currentMode === 'years'"
          key="year"
          class="base-datepicker-calendar__header-year"
          @click="onYearClick"
        >
          {{ getFormattedYearByMode(current.year) }}
        </div>
      </div>
      <plain-button
        icon="arrow-right"
        size="small"
        class="base-datepicker-calendar__next"
        @click="onNextClick"
      />
    </div>
    <div class="base-datepicker-calendar__body">
      <transition name="fade" mode="out-in">
        <base-datepicker-monthsgrid
          v-if="currentMode === 'months'"
          v-model="current.month"
          :locale="locale"
          @input="setMode(mode)"
        />
        <base-datepicker-yearsgrid
          v-else-if="currentMode === 'years'"
          v-model="current.year"
          :decade="current.decade"
          @input="setMode(mode)"
        />
        <base-datepicker-daysgrid
          v-else
          :value="value"
          :locale="locale"
          :current="current"
          :multiple="multiple"
          @input="onInput"
        />
      </transition>
    </div>
  </div>
</template>
<script>
import { format, isValid, localize, getDecade, getYear, getMonth, getDate } from 'date-fns';
import PlainButton from '../Buttons/PlainButton.vue';
import BaseDatepickerMonthsgrid from './BaseDatepickerMonthsgrid';
import BaseDatepickerYearsgrid from './BaseDatepickerYearsgrid';
import BaseDatepickerDaysgrid from './BaseDatepickerDaysgrid';
export default {
  name: 'BaseDatepickerCalendar',
  components: {
    PlainButton,
    BaseDatepickerDaysgrid,
    BaseDatepickerMonthsgrid,
    BaseDatepickerYearsgrid,
  },

  props: {
    theme: {
      type: String,
      default: 'default',
      validator: value => {
        return ['default', 'primary', 'secondary'].includes(value);
      },
    },
    value: {
      type: [String, Object, Date],
      required: true,
      validator: value => {
        if (!value || (!value.start && !value.end)) return true;
        if (value instanceof Date) return isValid(value);
        if (value instanceof Object) {
          return isValid(new Date(value.start)) && isValid(new Date(value.end));
        } else {
          return isValid(new Date(value));
        }
      },
    },
    initialDate: {
      type: [String, Date],
      default: null,
      validator: value => {
        if (!value) return true;
        if (value instanceof Date) return isValid(value);
        return isValid(new Date(value));
      },
    },
    mode: {
      type: String,
      default: 'days',
      validator: value => {
        return ['days', 'months', 'years'].includes(value);
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      initial: this.initialDate || Date.now(),
      current: {
        day: null,
        month: null,
        year: null,
        decade: null,
      },
      currentMode: this.mode,
      internalSelection: this.value,
    };
  },

  computed: {
    classes() {
      const prefix = 'base-datepicker-calendar';
      return {
        [`${prefix}--theme_${this.theme}`]: this.theme,
      };
    },
    selection: {
      get() {
        return this.internalSelection;
      },
      set(value) {
        this.internalSelection = value;
        this.$emit('input', value);
      },
    },
  },

  watch: {
    initialDate: function (value) {
      this.setCurrentView(value);
    },
  },

  created() {
    this.setCurrentView(this.initial);
  },

  methods: {
    setCurrentView(value) {
      const int = new Date(value);
      this.current = {
        day: getDate(int),
        month: getMonth(int),
        year: getYear(int),
        decade: getDecade(int),
      };
    },
    addMonthToCurrent(count) {
      if (this.current.month + count < 0) {
        this.current.month = 11;
        this.current.year -= 1;
      } else if (this.current.month + count > 11) {
        this.current.month = 0;
        this.current.year += 1;
      } else {
        this.current.month += count;
      }
    },
    setMode(value) {
      this.currentMode = value;
    },
    toggleMode(value) {
      if (this.currentMode === value) {
        this.currentMode = this.mode;
      } else {
        this.currentMode = value;
      }
    },
    // formatters
    getFormattedDate(date, to) {
      let opts = { locale: this.currentLocale };
      return format(date, to, opts);
    },
    getFormattedMonthByNumber(month) {
      return this.locale.localize.month(month);
    },
    getFormattedYearByMode(year) {
      if (this.currentMode === 'years') {
        return `${this.current.decade - 10}-${this.current.decade + 10}`;
      } else {
        return year;
      }
    },
    // event handlers
    onPrevClick() {
      if (this.currentMode === 'years') {
        this.current.decade -= 10;
      } else {
        this.addMonthToCurrent(-1);
        if (this.currentMode === 'months') {
          this.currentMode = this.mode;
        }
      }
    },
    onNextClick() {
      if (this.currentMode === 'years') {
        this.current.decade += 10;
      } else {
        this.addMonthToCurrent(1);
        if (this.currentMode === 'months') {
          this.currentMode = this.mode;
        }
      }
    },
    onYearClick() {
      if (this.currentMode === 'years') {
        this.current.decade = getDecade(new Date(this.current.year, 1, 1));
      }
      this.toggleMode('years');
    },
    onInput(value) {
      this.selection = value;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.base-datepicker-calendar {
  &__header {
    flexy(space-between, center);

    &-center {
      flexy(center, baseline);
    }

    &-month,
    &-year {
      margin: 0 0.1em;
      cursor: pointer;
      transition: color 0.3s, opacity 0.3s;

      &:hover {
        ^[0]--theme_primary & {
          color: $primary-color;
        }
        ^[0]--theme_secondary & {
          color: $secondary-color;
        }
        ^[0]--theme_default & {
          color: var(--calendar-hover);
        }
      }
    }

    &-month:first-letter {
      text-transform: uppercase;
    }
  }

  &__prev,
  &__next {
    flex: 0 0 1rem;
    color: var(--calendar-util-text);
  }

  &__body {
    padding-top: 0.75rem;
  }
}
</style>
