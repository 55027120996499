<template>
  <div class="base-datepicker-yearsgrid" @click="onClick">
    <div
      v-for="item in years"
      :key="item"
      :data-value="item"
      class="base-datepicker-yearsgrid__item small-text"
      :class="{ active: item === value }"
    >
      {{ item }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    decade: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      years: [],
    };
  },

  watch: {
    decade: function () {
      this.updateYears();
    },
  },

  beforeMount() {
    this.initYears();
  },

  methods: {
    initYears() {
      for (let i = this.decade - 10; i < this.decade + 11; i++) {
        this.years.push(i);
      }
    },
    updateYears() {
      this.years = [];
      this.initYears();
    },
    onClick(event) {
      this.$emit('input', parseInt(event.target.dataset.value));
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.base-datepicker-yearsgrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(7, 1fr);

  &__item {
    padding: 0.25em;
    text-align: center;
    cursor: pointer;
    border-radius: 2px;
    &:first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: var(--calendar-hover);
    }
    &.active {
      background: var(--calendar-selection);
      color: var(--calendar-selection-color);
    }
  }
}
</style>
