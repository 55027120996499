<template>
  <div class="base-datepicker-daysgrid">
    <div class="base-datepicker-daysgrid__weekdays smallest-text">
      <div v-for="(item, i) in weekdays" :key="i" class="base-datepicker-daysgrid__weekday">
        {{ item }}
      </div>
    </div>
    <div class="base-datepicker-daysgrid__days smallest-text" @click="onClick">
      <div v-for="(week, i) in daysGrid" :key="i" class="base-datepicker-daysgrid__week">
        <div
          v-for="(day, j) in week"
          :key="j"
          class="base-datepicker-daysgrid__day"
          :class="day.class"
          :data-date="day.date"
        >
          <div class="base-datepicker-daysgrid__day-text">
            {{ getFormattedDate(day.date, 'd') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  localize,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  eachWeekOfInterval,
  eachDayOfInterval,
  isToday,
  isPast,
  isFuture,
  isBefore,
  isAfter,
  isSameMonth,
  isValid,
  format,
  isWithinInterval,
  isSameDay,
  parseISO,
  set,
} from 'date-fns';
export default {
  props: {
    value: {
      type: [String, Object, Date],
      required: true,
      validator: value => {
        if (!value || (!value.start && !value.end)) return true;
        if (value instanceof Date) return isValid(value);
        if (value instanceof Object) {
          return isValid(new Date(value.start)) && isValid(new Date(value.end));
        } else {
          return isValid(new Date(value));
        }
      },
    },
    current: {
      type: Object,
      required: true,
      validator: value => {
        return value.day && value.month >= 0 && value.year;
      },
    },
    locale: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      weekdays: [],
      internalSelection: this.value,
    };
  },

  computed: {
    month() {
      return this.current.month;
    },
    year() {
      return this.current.year;
    },
    selection: {
      get() {
        return this.internalSelection;
      },
      set(value) {
        this.internalSelection = value;
        this.$emit('input', value);
      },
    },
    daysGrid() {
      let currentDate = new Date(this.current.year, this.current.month, this.current.day);
      let opts = { locale: this.locale };
      let currentMonth = {
        start: startOfMonth(currentDate, opts),
        end: endOfMonth(currentDate, opts),
      };
      let currentGridInterval = {
        start: startOfWeek(currentMonth.start, opts),
        end: endOfWeek(currentMonth.end, opts),
      };
      return eachWeekOfInterval(currentGridInterval, opts).map((week, i) => {
        let currentWeekInterval = {
          start: week,
          end: endOfWeek(week, opts),
        };
        return eachDayOfInterval(currentWeekInterval, opts).map((day, i) => {
          let classes = {
            ['is-today']: isToday(day),
            ['is-past']: isPast(day),
            ['is-future']: isFuture(day),
            ['is-current-month']: isSameMonth(day, currentDate),
            ['is-prev-month']: isBefore(day, currentMonth.start),
            ['is-next-month']: isAfter(day, currentMonth.end),
          };
          if (this.hasSelection) {
            let isSelectedDay = this.isSelectedDay(day);
            classes['is-selected'] = isSelectedDay.isSelected;
            classes['is-in-range'] = isSelectedDay.isInRange;
            classes['is-range-start'] = isSelectedDay.isRangeStart;
            classes['is-range-end'] = isSelectedDay.isRangeEnd;
          }
          return {
            date: day,
            class: classes,
          };
        });
      });
    },
    hasSelection() {
      return this.multiple ? !!this.selection.start && !!this.selection.end : !!this.selection;
    },
  },

  watch: {
    locale: function () {
      this.updateLocale();
    },
    value: function (value) {
      this.selection = value;
    },
  },

  beforeMount() {
    this.init();
  },

  methods: {
    init() {
      this.setLocalizedWeekdays();
    },
    setLocalizedWeekdays() {
      let firstDay = this.locale.options.weekStartsOn;
      let opts = { width: 'short' };
      for (let i = firstDay; i < 7; i++) {
        this.weekdays.push(this.locale.localize.day(i, opts));
      }
      for (let i = 0; i < firstDay; i++) {
        this.weekdays.push(this.locale.localize.day(i, opts));
      }
    },
    updateLocale() {
      this.weekdays = [];
      this.setLocalizedWeekdays();
    },
    clearSelection() {
      if (this.multiple) {
        this.selection.start = null;
        this.selection.end = null;
      } else {
        this.selection = null;
      }
    },
    getFormattedDate(date, to) {
      return format(date, to);
    },
    isSelectedDay(day) {
      // time for fix intervals bug
      let defTimeStart = { hours: 0, minutes: 0, seconds: 0 };
      let defTimeEnd = { hours: 23, minutes: 59, seconds: 59 };
      let dayDate = set(new Date(day), { hours: 1 });
      let selectionObj = {
        isSelected: false,
        isInRange: false,
        isRangeStart: false,
        isRangeEnd: false,
      };
      if (!this.hasSelection) return selectionObj;
      if (this.multiple) {
        let selectionInterval = {
          start: this.selection.start ? set(new Date(this.selection.start), defTimeStart) : null,
          end: this.selection.end ? set(new Date(this.selection.end), defTimeEnd) : null,
        };
        if (
          selectionInterval.start &&
          selectionInterval.end &&
          isWithinInterval(dayDate, selectionInterval)
        ) {
          selectionObj.isInRange = true;
          if (isSameDay(dayDate, selectionInterval.start)) {
            selectionObj.isRangeStart = true;
            selectionObj.isSelected = true;
          }
          if (isSameDay(dayDate, selectionInterval.end)) {
            selectionObj.isRangeEnd = true;
            selectionObj.isSelected = true;
          }
        }
      } else {
        if (isSameDay(dayDate, new Date(this.selection))) {
          selectionObj.isSelected = true;
        }
      }
      return selectionObj;
    },
    onClick(event) {
      if (!event.target.dataset.date) return;
      if (this.multiple) {
        let dateObj = new Date(event.target.dataset.date);
        let selectionInterval = {
          start: this.selection.start ? new Date(this.selection.start) : null,
          end: this.selection.end ? new Date(this.selection.end) : null,
        };
        if (
          isSameDay(dateObj, selectionInterval.start) &&
          isSameDay(dateObj, selectionInterval.end)
        ) {
          this.clearSelection();
        } else if (!selectionInterval.start || !selectionInterval.end) {
          this.selection.start = dateObj;
          this.selection.end = dateObj;
        } else if (selectionInterval.end && isBefore(dateObj, selectionInterval.end)) {
          if (!isSameDay(dateObj, selectionInterval.start)) {
            this.selection.start = dateObj;
          } else {
            this.selection.start = this.selection.end;
          }
        } else if (selectionInterval.start && isAfter(dateObj, selectionInterval.start)) {
          if (!isSameDay(dateObj, selectionInterval.end)) {
            this.selection.end = dateObj;
          } else {
            this.selection.end = this.selection.start;
          }
        }
      } else {
        this.selection = new Date(event.target.dataset.date);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.base-datepicker-daysgrid {
  &__weekdays,
  &__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
  }

  &__weekdays {
    padding-bottom: 0.25rem;
    margin-bottom: 0.25rem;
    border-bottom: 1px solid var(--calendar-util-border);
    color: var(--calendar-util-text);
    text-transform: uppercase;
    transition: color $anim-base-duration ease, border-color $anim-base-duration ease;
  }

  &__day {
    position: relative;
    padding: 0;
    cursor: default;

    &-text {
      display: inline-block;
      position: relative;
      width: 2em;
      line-height: 2;
      vertical-align: middle;
      pointer-events: none;
      z-index: 1;
    }

    &.is-past {
      color: $grey-dark;
    }
    &.is-today,
    &.is-future {
      color: $text-color;
    }
    &.is-prev-month,
    &.is-next-month {
      opacity: 0;
      pointer-events: none;
    }
    &.is-current-month {
      cursor: pointer;

      &:hover ^[-1]-text {
        color: var(--calendar-hover);
      }
    }
    &.is-in-range,
    &.is-selected {
      color: var(--calendar-selection-color);

      & ^[-1]-text,
      &:hover ^[-1]-text {
        background: var(--calendar-selection);
        color: var(--calendar-selection-color);
      }
    }
    &.is-in-range::before {
      content: '';
      display: block;
      absolute(50%, -1px, 0, -1px);
      height: 2em;
      transform: translateY(-50%);
      background: var(--calendar-selection);
    }
    &.is-range-start::before {
      //left: calc((100% - 2em) / 2);
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    &.is-range-end::before {
      //right: calc((100% - 2em) / 2);
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
}
</style>
