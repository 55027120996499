<template>
  <div class="base-datepicker-monthsgrid" @click="onClick">
    <div
      v-for="item in months"
      :key="item.value"
      :data-value="item.value"
      class="base-datepicker-monthsgrid__item small-text"
      :class="{ active: item.value === value }"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    locale: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      months: [],
    };
  },

  beforeMount() {
    this.initMonths();
  },

  methods: {
    initMonths() {
      for (let i = 0; i < 12; i++) {
        this.months.push({
          name: this.locale.localize.month(i).substr(0, 3),
          value: i,
        });
      }
    },
    onClick(event) {
      this.$emit('input', parseInt(event.target.dataset.value));
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.base-datepicker-monthsgrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);

  &__item {
    padding: 0.25em;
    text-align: center;
    cursor: pointer;
    border-radius: 2px;
    &:first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: var(--calendar-hover);
    }
    &.active {
      background: var(--calendar-selection);
      color: var(--calendar-selection-color);
    }
  }
}
</style>
