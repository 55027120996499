<template>
  <div class="base-buttons-group">
    <base-button
      v-for="item in list"
      :key="item.value"
      size="smallest"
      color="default"
      theme="outlined"
      watch-active
      :active="item.value === internalValue"
      v-bind="item.props ? item.props : null"
      class="base-buttons-group__item"
      @click="onClick(item)"
    >
      {{ item.title }}
    </base-button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonsGroup',
  props: {
    list: {
      type: Array,
      required: true,
      validator: arr => arr.every(item => item.value && item.title),
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value: function (value) {
      this.internalValue = value;
    },
    internalValue: function (value) {
      this.$emit('change', value);
    },
  },
  methods: {
    onClick(item) {
      if (this.internalValue !== item.value) this.internalValue = item.value;
      else this.internalValue = '';
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-buttons-group {
  flexy(flex-start, center);

  &__item {
    &:first-child,
    & + &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    & + & {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }
  }
}
</style>
