<template>
  <div class="base-datepicker" :class="classes">
    <base-button
      theme="outlined"
      color="default"
      size="smallest"
      class="base-datepicker__selection"
      @click="onClickToggler"
    >
      {{ selectionText }}
    </base-button>
    <transition name="fade">
      <div v-show="isOpen" v-click-outside.capture="onClickOutside" class="base-datepicker__popup">
        <div class="base-datepicker__popup-back" @click.stop="close"></div>
        <div class="base-datepicker__popup-window">
          <base-datepicker-calendar
            :multiple="multiple"
            :theme="theme"
            :value="immediateSelection"
            :initial-date="initialCalendarDate"
            :locale="currentLocale"
            class="base-datepicker__calendar"
            @input="onInput"
          />
          <div v-if="showRanges" class="base-datepicker__ranges">
            <base-button
              v-for="(val, key) in ranges"
              :key="key"
              size="smallest"
              color="default"
              :theme="
                val.start == immediateSelection.start && val.end == immediateSelection.end
                  ? 'filled'
                  : 'outlined'
              "
              class="base-datepicker__action base-datepicker__action--range"
              @click="onClickRange(val)"
            >
              {{ $t(key) }}
            </base-button>
          </div>
          <div class="base-datepicker__actions">
            <base-button
              size="smallest"
              :disabled="!hasImmediateSelection"
              class="base-datepicker__action base-datepicker__action--apply"
              @click="onClickApply"
            >
              {{ $t('apply') }}
            </base-button>
            <base-button
              theme="outlined"
              size="smallest"
              class="base-datepicker__action base-datepicker__action--clear"
              @click="onClickClear"
            >
              {{ $t('clear') }}
            </base-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';
import BaseDatepickerCalendar from './BaseDatepickerCalendar';
import { isValid, format, isSameDay, addDays, setDay, set } from 'date-fns';
import { ru } from 'date-fns/locale';
const locales = {
  ru: ru,
  //enUS: en-US,
};
export default {
  name: 'BaseDatepicker',
  components: { BaseDatepickerCalendar },

  directives: { ClickOutside },

  props: {
    placeholder: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'default',
      validator: value => {
        return ['default', 'primary', 'secondary'].includes(value);
      },
    },
    value: {
      type: [String, Object, Date],
      default: null,
      validator: value => {
        if (!value || (!value.start && !value.end)) return true;
        if (value instanceof Date) return isValid(value);
        if (value instanceof Object) {
          return isValid(new Date(value.start)) && isValid(new Date(value.end));
        } else {
          return isValid(new Date(value));
        }
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isClosed: {
      type: Boolean,
      default: false,
    },
    showRanges: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      internalSelection: this.value,
      immediateSelection: this.value,
      initialCalendarDate: new Date(),
      noValue: '',
      noImmediateValue: '',
      direction: 'bottom',
      ranges: {},
    };
  },

  computed: {
    currentLocale() {
      return this.$i18n ? locales[this.$i18n.locale] : locales.ru;
    },
    classes() {
      const prefix = 'base-datepicker';
      return {
        [`${prefix}--theme_${this.theme}`]: this.theme,
        [`${prefix}--dir_${this.direction}`]: this.direction,
        [`${prefix}--open`]: this.isOpen,
      };
    },
    selection: {
      get() {
        return this.internalSelection;
      },
      set(value) {
        this.internalSelection = value;
        this.$emit('input', value);
      },
    },
    selectionText() {
      if (!this.hasSelection) return this.placeholder ? this.placeholder : this.$t('apply');
      let opts = { locale: this.currentLocale };
      let str;
      if (!this.multiple) {
        str = format(new Date(this.selection), 'dd.MM.yyyy', opts);
      } else {
        let startDate = new Date(this.selection.start);
        let endDate = new Date(this.selection.end);
        if (isSameDay(startDate, endDate)) {
          str = format(startDate, 'dd.MM.yyyy', opts);
        } else {
          let to = 'dd.MM.yyyy';
          str = `${format(startDate, to, opts)} - ${format(endDate, to, opts)}`;
        }
      }
      return str;
    },
    hasSelection() {
      return this.multiple ? !!this.selection.start && !!this.selection.end : !!this.selection;
    },
    hasImmediateSelection() {
      return this.multiple
        ? !!this.immediateSelection.start && !!this.immediateSelection.end
        : !!this.immediateSelection;
    },
  },

  watch: {
    isClosed: function (value) {
      if (value && this.isOpen) {
        this.close();
      }
    },
    showRanges: function (value) {
      if (value) this.initRanges();
    },
    value: function (value) {
      this.onClickRange(value);
      this.onClickApply();
    },
  },

  beforeMount() {
    if (this.showRanges) this.initRanges();
    if (this.multiple) {
      this.initMultiple();
    }
  },

  methods: {
    initMultiple() {
      this.immediateSelection = {
        start: this.value.start ? this.value.start : null,
        end: this.value.end ? this.value.end : null,
      };
      this.noValue = {};
      this.noImmediateValue = { start: null, end: null };
    },
    initRanges() {
      let defTimeStart = { hours: 0, minutes: 0, seconds: 0 };
      let defTimeEnd = { hours: 23, minutes: 59, seconds: 59 };
      let today = new Date();
      let tomorrow = addDays(today, 1);
      let opts = { locale: this.currentLocale };
      this.ranges.today = this.multiple
        ? { start: set(today, defTimeStart), end: set(today, defTimeEnd) }
        : today;
      this.ranges.tomorrow = this.multiple
        ? { start: set(tomorrow, defTimeStart), end: set(tomorrow, defTimeEnd) }
        : tomorrow;
      if (this.multiple) {
        this.ranges.weekend = {
          start: set(setDay(today, 6, opts), defTimeStart),
          end: set(setDay(today, 0, opts), defTimeEnd),
        };
      }
    },
    setValue(prop, value) {
      this[prop] = this.multiple ? { ...value } : value;
      this.$emit('change', this[prop]);
    },
    updateInitialCalendarDate(value) {
      this.initialCalendarDate = value;
    },
    open() {
      this.isOpen = true;
      if (this.hasImmediateSelection) {
        this.updateInitialCalendarDate(
          this.multiple ? new Date(this.immediateSelection.start) : this.immediateSelection
        );
      }
      this.$emit('open');
    },
    close() {
      this.isOpen = false;
      if (this.hasImmediateSelection) {
        setTimeout(() => {
          this.updateInitialCalendarDate(new Date());
        }, 300);
      }
      this.$emit('close');
    },
    onInput(value) {
      this.immediateSelection = value;
    },
    onClickToggler() {
      if (this.isOpen) {
        this.close();
      } else {
        setTimeout(() => {
          this.open();
        });
      }
    },
    onClickOutside(e) {
      if (this.isOpen) {
        this.close();
      }
    },
    onClickApply() {
      if (this.hasImmediateSelection) {
        this.setValue('selection', this.immediateSelection);
        this.close();
      }
    },
    onClickClear() {
      this.setValue('immediateSelection', this.noImmediateValue);
      this.setValue('selection', this.noValue);
    },
    onClickRange(value) {
      this.setValue('immediateSelection', value);
      this.updateInitialCalendarDate(this.multiple ? value.start : value);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "apply": "Выбрать",
    "clear": "Очистить",
    "today": "Сегодня",
    "tomorrow": "Завтра",
    "weekend": "На этих выходных"
  },
  "en": {
    "apply": "Apply",
    "clear": "CLear",
    "today": "Today",
    "tomorrow": "Tomorrow",
    "weekend": "On this weekend"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.base-datepicker {
  display: inline-flex;
  position: relative;

  &::before {
    content: '';
    display: block;
    absolute(0, 50%);
    margin-left: -0.5em;
    z-index: 3;
    opacity: 0;
    transition: opacity 0.3s;

    ^[0]--open& {
      opacity: 1;
    }

    ^[0]--dir_top& {
      bottom: 100%;
      margin-bottom: 0.5em;
      triangle(0.5em, bottom, 1em, var(--calendar-arrow));
    }
    ^[0]--dir_bottom& {
      top: 100%;
      margin-top: 0.5em;
      triangle(0.5em, top, 1em, var(--calendar-arrow));
    }
  }

  &__popup {
    absolute(0, 0px);
    z-index: 2;
    width: r(200px);
    max-width: 'calc(100vw - %s * 2)' % $gutter;

    +breakpoint(ms-and-down) {
      position: fixed;
      max-height: 75vh;
      overflow-y: auto;
    }
    +breakpoint(xs-only) {
      max-height: 100vh;
    }

    ^[0]--dir_top & {
      bottom: 100%;
      margin-bottom: 1rem;

      +breakpoint(ms-and-down) {
        bottom: $gutter;
        margin-bottom: 0;
      }
    }
    ^[0]--dir_bottom & {
      top: 100%;
      margin-top: 1rem;

      +breakpoint(ms-and-down) {
        top: - 2 * $gutter;
        margin-top: 0;
      }
    }
    &-back {
      display: none;

      +breakpoint(ms-and-down) {
        display: block;
        background: rgba(0, 0, 0, 0.5);
        fixed(-40vh, (-1 * $gutter), -60vh, (-1 * $gutter));
      }
    }
    &-window {
      position: relative;
      background: var(--calendar-bg);
      border: 1px solid var(--calendar-border);
      color: var(--calendar-color);
      padding: 1rem;
      border-radius: $border-radius-small;
      transition: background $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease;
    }
  }

  &__ranges,
  &__actions {
    flexy(flex-start, center, wrap);
    margin: 0.5rem -0.5rem -0.5rem;
  }

  &__ranges {
    +breakpoint(xs-only) {
      margin: 0.5rem -0.25rem -0.25rem;
    }
  }

  &__action {
    margin: 0.25rem;

    &--range {
      +breakpoint(xs-only) {
        padding: 0 0.25rem;
        margin: 0.25rem;
      }
    }

    &--apply,
    &--clear {
      min-width: unset;
      flex: 0 1 'calc(50% - %s)' % 1rem;
    }
  }
}
</style>
