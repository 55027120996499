<template>
  <div class="base-datepicker-filter">
    <base-buttons-group
      :list="periodsList"
      :value="periodValue"
      class="base-datepicker-filter__periods"
      @change="onPeriodChange"
    />
    <base-datepicker
      :value="internalValue"
      multiple
      class="base-datepicker-filter__datepicker"
      @change="onChange"
    />
  </div>
</template>

<script>
import BaseDatepicker from './BaseDatepicker';
import BaseButtonsGroup from '@/components/Buttons/BaseButtonsGroup';
import { isValid, addWeeks, addMonths, addYears, isSameDay, set } from 'date-fns';
export default {
  name: 'BaseDatepickerFilter',
  components: { BaseDatepicker, BaseButtonsGroup },
  props: {
    value: {
      type: Object,
      default: () => ({ start: new Date(), end: new Date() }),
      validator: val => {
        return val.start && isValid(new Date(val.start)) && val.end && isValid(new Date(val.end));
      },
    },
    periods: {
      type: Array,
      default: () => [],
      validator: arr =>
        arr.every(item => ['week', 'month', 'quarter', 'halfyear', 'year'].includes(item)),
    },
    period: {
      type: String,
      default: '',
      validator: value => {
        return !value || ['week', 'month', 'quarter', 'halfyear', 'year'].includes(value);
      },
    },
  },
  data() {
    return {
      defValue: { start: new Date(), end: new Date() },
      internalValue: this.value,
      backupValue: {},
      backupPeriod: '',
      periodValue: this.period,
      standartPeriodsSettings: {
        week: { func: addWeeks, value: -1 },
        month: { func: addMonths, value: -1 },
        quarter: { func: addMonths, value: -3 },
        halfyear: { func: addMonths, value: -6 },
        year: { func: addYears, value: -1 },
      },
      standartPeriods: {},
    };
  },
  computed: {
    periodsList() {
      return this.periods.map(item => ({ title: this.$t(item), value: item }));
    },
  },
  watch: {
    periods: function () {
      console.log(this.period);
      this.initPeriods();
    },
    periodValue: {
      handler: function (value) {
        this.filterDocs(this.value);
        this.$emit('change-period', value);
      },
      immediate: true,
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.filterDocs(this.backupValue, this.backupPeriod);
  },
  methods: {
    filterDocs(date, t = null) {
      const start = this.$d(date.start, 'short');
      const end = this.$d(date.end, 'short');
      const period = this.periods.includes(this.periodValue) ? this.periodValue : 'other';
      const payload = {
        createdatestart: start.split('.').reverse().join('-'),
        createdateend: end.split('.').reverse().join('-'),
        createdate: t ? t : period,
        func: 'payment.filter',
      };
      this.$store
        .dispatch('moduleBilling/modulePayment/fetchBiilingDocsList', payload)
        .then(data => {
          if (data) this.$store.dispatch('moduleBilling/moduleDocs/modulePayment/fetchList');
        });
    },
    init() {
      this.initPeriods();
      if (this.period) {
        this.internalValue = this.standartPeriods[this.period];
        this.backupValue = this.internalValue;
        this.backupPeriod = this.period;
      }
      this.$emit('init', this.internalValue);
    },
    initPeriods() {
      this.periods.forEach(item => {
        if (this.standartPeriodsSettings[item]) {
          const settings = this.standartPeriodsSettings[item];
          this.standartPeriods[item] = this.getNormalizedInterval({
            start: settings.func(new Date(), settings.value),
            end: new Date(),
          });
        }
      });
    },
    onChange(val) {
      // console.log('val-change', val);
      const int = this.getNormalizedInterval(val);
      if (!this.isEqualInterval(int, this.internalValue)) {
        this.internalValue = int;
        this.$emit('change', this.internalValue);
      }
      if (this.periodValue && !this.isEqualInterval(this.standartPeriods[this.periodValue], int)) {
        this.periodValue = '';
      }
    },
    onPeriodChange(value) {
      // console.log('periodValue', value);
      this.periodValue = value;
      if (value) {
        this.internalValue = this.getNormalizedInterval(this.standartPeriods[this.periodValue]);
        this.$emit('change', this.internalValue);
      }
    },
    isEqualInterval(a, b) {
      return (
        a &&
        a.start &&
        a.end &&
        b &&
        b.start &&
        b.end &&
        isSameDay(a.start, b.start) &&
        isSameDay(a.end, b.end)
      );
    },
    getNormalizedInterval(val) {
      const int = val && val.start && val.end ? val : this.defValue;
      int.start = set(int.start, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      int.end = set(int.end, { hours: 23, minutes: 59, seconds: 59, milliseconds: 0 });
      // console.log(int, this.$d(int.start, 'short'));
      return int;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "week": "Неделя",
    "month": "Месяц",
    "quarter": "Квартал",
    "halfyear": "Полгода",
    "year": "Год"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-datepicker-filter {
  flexy(flex-start, center, wrap);
  margin: -0.5rem;

  +breakpoint(sm-and-up) {
    margin: -0.75rem;
  }

  &__periods,
  &__datepicker {
    margin: 0.5rem;

    +breakpoint(sm-and-up) {
      margin: 0.75rem;
    }
  }
}
</style>
